<template>
  <v-card elevation="0">
    <v-container
      grid-list-xs
      name="carrinho-local"
      v-if="carrinho.produtos.length == 0"
    >
      <v-row v-if="carrinho.produtos.length == 0 && cart.length > 0">
        <v-col cols="12" class="secondary white--text justify-center">
          <h2 class="text-center">SUMÁRIO DO CARRINHO DE COMPRAS TEMPORÁRIO</h2>
          <h4 class="text-center">
            O teu carrinho temporário contém:
            {{ cart.length }}
            <span v-if="cart.length == 1"> produto.</span>
            <span v-else> produtos.</span>
          </h4>
        </v-col>
      </v-row>

      <div v-if="cart.length > 0" name="carrinho-local">
        <div v-for="(cartStorage, index) in cart" :key="index">
          <div
            class="cart-item d-flex justify-space-between align-center px-2 py-3"
          >
            <div class="d-flex flex-column align-center">
              <v-col>
                <v-btn
                  :disabled="cartStorage.quantity == 1"
                  name="btn-minus "
                  @click="decreaseQuantity(cartStorage)"
                  class="mx-2"
                  fab
                  outlined
                  dark
                  x-small
                  color="secondary"
                >
                  <v-icon dark> mdi-minus </v-icon>
                </v-btn>
                <span> {{ cartStorage.quantity }}</span>
                <v-btn
                  name="btn-plus"
                  @click="increaseQuantity(cartStorage)"
                  class="mx-2"
                  fab
                  dark
                  x-small
                  color="secondary"
                >
                  <v-icon dark> mdi-plus </v-icon>
                </v-btn>
              </v-col>
            </div>

            <router-link
              :to="{
                name: 'ProdutoDetalhes',
                params: { produto_id: cartStorage.uuid },
              }"
              style="text-decoration: none"
            >
              <v-avatar tile size="76">
                <img
                  :title="'Ver detalhes do: ' + cartStorage.designacao"
                  :alt="'Ver detalhes do Produto'"
                  width="95"
                  :src="SERVER + cartStorage.imagem_produto"
                />
              </v-avatar>
            </router-link>

            <div class="d-flex flex-column align-center">
              <span>Disponiblidade</span>

              <h5 class="font-weight-bold green--text">
                <span class="green--text font-weight-bolder"> Disponível </span>
              </h5>
            </div>
            <div class="col-5">
              <h3>
                {{ cartStorage.designacao }}
              </h3>
              <h4 class="font-weight-medium">
                {{ formatePrice(cartStorage.pvp) }}
                X
                {{ cartStorage.quantity }}
              </h4>
              <h4 class="secondary--text font-weight-bold">
                {{ formatePrice(calculateSubtotalLocal(cartStorage)) }}
                KZ
              </h4>
            </div>

            <v-btn
              @click="showDialogDeleteFromCartStorage(cartStorage)"
              color="error"
              rounded
              text
              name="btn-eliminar-do-carrinho"
            >
              <v-icon md>mdi-delete</v-icon>
            </v-btn>
          </div>

          <v-divider color="red" />
        </div>

        <v-card-text v-if="cart.length > 0 && !overlay">
          <v-card-text>
            <v-row>
              <v-col cols="4">
                <div cols="12" md="6" class="text-left secondary--text">
                  <h3 class="font-weight-bold">
                    Total Produtos: {{ formatePrice(this.totalPrice) }}
                    KZ
                  </h3>
                </div>
              </v-col>
              <v-spacer />
              <v-col cols="2">
                <v-spacer />
                <v-btn
                  color="error"
                  @click="showDialogDeleteAllFromCartStorage()"
                >
                  <v-icon>mdi-delete</v-icon> Eliminar todos
                </v-btn>
              </v-col>
            </v-row>

            <h2
              name="total-carrinho"
              class="text-center font-weight-bold primary--text"
            >
              TOTAL:
              {{ formatePrice(totalPrice) }}
              KZ
            </h2>
            <p class="text-center">Preço com IVA incluido à taxa em vigor</p>

            <v-alert
              name="carrinho-update"
              dismissible
              v-if="dados"
              dense
              text
              type="success"
            >
              {{ dados }}
            </v-alert>
          </v-card-text>
        </v-card-text>
      </div>

      <!-- <div>
            <ul>
              <li v-for="(product, index) in cart" :key="index">
                {{ product.designacao }} - QTY: {{ product.quantity }} - Preço:
                {{ product.pvp }} KZ
                <v-btn
                  text
                  outlined
                  color="error"
                  class="mb-1 mr-2"
                  @click="decreaseQuantity(product)"
                >
                  <v-icon>mdi-minus</v-icon>
                </v-btn>
                <v-btn color="info" class="mr-2" @click="increaseQuantity(product)"
                  ><v-icon>mdi-plus</v-icon>
                </v-btn>

                <v-btn class="error" text @click="removeFromStorageCart(product)"
                  ><v-icon>delete</v-icon>
                </v-btn>
              </li>
            </ul>
            <p>Total Products: {{ totalProducts }}</p>
            <p>Total Price: {{ totalPrice }}</p>
          </div> -->
    </v-container>
    <!-- <h1>{{ carrinho.produtos.length }}</h1> -->
    <v-container
      class="mb-60"
      v-if="cart.length == 0 && carrinho.produtos.length == 0 && !token"
    >
      <div
        class="empty-cart-sidebar d-flex flex-column justify-center align-center mt-10"
      >
        <v-avatar size="90" tile class="mb-8">
          <img src="@/assets/images/empty-cart.png" alt="" />
        </v-avatar>
        <h2 class="red--text text--darken-2 px-5 mx-10 text-center mt-2">
          O teu carrinho local está vazio! Queira fazer login para ver os teus
          produtos.
        </h2>
      </div>
    </v-container>

    <v-container
      class="mb-60"
      name="carrinho-da-bd"
      v-if="
        carrinho.produtos.length == 0 && cart.length == 0 && !loading && token
      "
    >
      <div
        class="empty-cart-sidebar d-flex flex-column justify-center align-center mt-10"
      >
        <v-avatar size="90" tile class="mb-8">
          <img src="@/assets/images/empty-cart.png" alt="" />
        </v-avatar>
        <h3 class="red--text text--darken-2 px-5 mx-10 text-center">
          O teu carrinho está vazio! Queira adicionar qualquer produto que
          desejar.
        </h3>
        <v-btn color="error" text outlined @click="goToProdutos()">
          Ver Produtos</v-btn
        >
      </div>
    </v-container>

    <div v-if="!loading && carrinho.produtos.length > 0">
      <v-stepper alt-labels v-model="passoInicial">
        <v-stepper-header>
          <v-stepper-step :complete="passoInicial > 1" step="1">
            RESUMO
          </v-stepper-step>

          <v-divider />

          <v-stepper-step step="2" :complete="passoInicial > 2">
            <div class="text-center">ENTRAR/CRIAR CONTA</div>
          </v-stepper-step>

          <v-divider />

          <v-stepper-step step="3" :complete="passoInicial > 3">
            ENDEREÇO
          </v-stepper-step>

          <v-divider />

          <!-- <v-stepper-step
                  name="opcao-de-envio"
                  step="4"
                  :complete="passoInicial > 4"
                >
                  ENVIO
                </v-stepper-step> -->

          <v-divider />

          <v-stepper-step step="5" :complete="passoInicial > 5">
            PAGAMENTO
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content name="stepper-1" step="1">
            <v-container class="mb-60">
              <!-- <h1>{{  carrinho.produtos.length[0] }}</h1> -->
              <!-- <v-container style="height: 400px;"> -->
              <!-- <v-row name="carregando..."
                    v-if="overlay"
                    class="fill-height"
                    align-content="center"
                    justify="center"
                  >
                    <v-col
                      class="text-subtitle-1 text-center secondary--text"
                      cols="12"
                      style="margin-bottom: -20px"
                    >
                      Carregando...
                    </v-col>
                    <v-col cols="6">
                      <v-progress-linear
                        color="secondary accent-4"
                        indeterminate
                        rounded
                        dense
                        height="2"
                      ></v-progress-linear>
                    </v-col>
                  </v-row> -->
              <!-- </v-container>  -->
              <v-row v-if="carrinho.produtos.length > 0">
                <v-col cols="12" class="secondary white--text justify-center">
                  <!-- <h6>{{ carrinho.cobrarTaxaEntrega }}</h6> -->
                  <h2 class="text-center">SUMÁRIO DO CARRINHO DE COMPRAS</h2>
                  <h4 class="text-center">
                    O teu carrinho contém:
                    {{ carrinho.produtos.length }}
                    <span v-if="carrinho.produtos.length == 1"> produto.</span>
                    <span v-else> produtos.</span>
                  </h4>
                </v-col>
              </v-row>

              <div name="mobile-carrinho" v-if="!isWeb">
                <v-row>
                  <v-col cols="12">
                    <v-card
                      elevation="0"
                      v-for="(cart, index) in carrinho.produtos"
                      :key="index++"
                    >
                      <v-card-text>
                        <v-list-item>
                          <router-link
                            :to="{
                              name: 'ProdutoDetalhes',
                              params: { produto_id: cart.uuid },
                            }"
                            style="text-decoration: none"
                          >
                            <v-list-item-avatar size="60" rounded>
                              <v-img
                                :alt="cart.designacao"
                                :src="SERVER + cart.imagem_produto"
                              ></v-img>
                            </v-list-item-avatar>
                          </router-link>
                          <v-list-item-content>
                            <v-list-item-title
                              class="font-weight-bold text-uppercase"
                              >{{ cart.designacao }}</v-list-item-title
                            >
                            <v-list-item-subtitle>
                              <h4 class="grey--text font-weight-bold">
                                {{ formatePrice(cart.pvp) }}
                                X {{ cart.quantidade }}
                              </h4>
                              <br />
                              <h4
                                style="margin-top: -12px"
                                class="primary--text font-weight-bold"
                              >
                                {{ formatePrice(parseFloat(cart.subtotal)) }}
                                KZ
                              </h4>
                            </v-list-item-subtitle>
                            <v-list-item-subtitle>
                              <div>
                                <v-col>
                                  <v-btn
                                    :disabled="
                                      cart.quantidade == 1 || loadingData
                                    "
                                    :title="
                                      'Diminuir menos uma unidade de(o) ' +
                                      cart.designacao +
                                      ' no carrinho'
                                    "
                                    name="btn-minus "
                                    @click="decreaseProductInCart(cart.uuid)"
                                    class="mx-2"
                                    fab
                                    outlined
                                    dark
                                    x-small
                                    color="secondary"
                                  >
                                    <v-icon dark> mdi-minus </v-icon>
                                  </v-btn>
                                  <span> {{ cart.quantidade }}</span>

                                  <v-btn
                                    name="btn-plus"
                                    :disabled="
                                      loadingData || calcularStock(cart) == 0
                                    "
                                    :title="
                                      'Aumentar mais uma unidade de(o) ' +
                                      cart.designacao +
                                      ' no carrinho'
                                    "
                                    @click="encreaseProductInCart(cart.uuid)"
                                    class="mx-2"
                                    fab
                                    dark
                                    x-small
                                    color="secondary"
                                  >
                                    <v-icon dark> mdi-plus </v-icon>
                                  </v-btn>
                                  <v-btn
                                    @click="showDialogDeleteFromCart(cart)"
                                    color="error"
                                    rounded
                                    text
                                  >
                                    <v-icon md>mdi-delete</v-icon>
                                  </v-btn>
                                </v-col>
                              </div>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>

                        <v-row class="justify-center">
                          <span class="mr-2">Stock: </span>
                          <h5
                            class="font-weight-bold green--text"
                            v-if="
                              cart.quantidade > 0 && calcularStock(cart) >= 1
                            "
                          >
                            <span class="green--text font-weight-bolder">
                              restam
                              {{ calcularStock(cart) }}
                              <span v-if="calcularStock(cart) == 1">
                                item
                              </span>
                              <span v-else> itens </span>
                            </span>
                          </h5>
                          <h5 class="font-weight-bold" v-else>
                            <span class="red--text">
                              Produto esgotado temporariamente
                            </span>
                          </h5>
                        </v-row>
                      </v-card-text>

                      <v-divider color="grey" />
                    </v-card>
                  </v-col>
                </v-row>
              </div>

              <div v-if="carrinho.produtos.length > 0 && isWeb">
                <div v-for="(cart, index) in carrinho.produtos" :key="index++">
                  <div
                    class="cart-item d-flex justify-space-between align-center px-2 py-3"
                  >
                    <div class="d-flex flex-column align-center">
                      <v-col>
                        <v-btn
                          :disabled="cart.quantidade == 1 || loadingData"
                          :title="
                            'Diminuir menos uma unidade de(o) ' +
                            cart.designacao +
                            ' no carrinho'
                          "
                          name="btn-minus "
                          @click="decreaseProductInCart(cart.uuid)"
                          class="mx-2"
                          fab
                          outlined
                          dark
                          x-small
                          color="secondary"
                        >
                          <v-icon dark> mdi-minus </v-icon>
                        </v-btn>
                        <span> {{ cart.quantidade }}</span>

                        <v-btn
                          name="btn-plus"
                          :disabled="loadingData || calcularStock(cart) == 0"
                          :title="
                            'Aumentar mais uma unidade de(o) ' +
                            cart.designacao +
                            ' no carrinho'
                          "
                          @click="encreaseProductInCart(cart.uuid)"
                          class="mx-2"
                          fab
                          dark
                          x-small
                          color="secondary"
                        >
                          <v-icon dark> mdi-plus </v-icon>
                        </v-btn>
                      </v-col>
                    </div>

                    <router-link
                      :to="{
                        name: 'ProdutoDetalhes',
                        params: { produto_id: cart.uuid },
                      }"
                      style="text-decoration: none"
                    >
                      <v-avatar tile size="76">
                        <img
                          :title="'Ver detalhes do: ' + cart.designacao"
                          :alt="'Ver detalhes do Produto'"
                          width="95"
                          :src="SERVER + cart.imagem_produto"
                        />
                      </v-avatar>
                    </router-link>

                    <div class="d-flex flex-column align-center">
                      <span>Disponiblidade</span>
                      <h5
                        class="font-weight-bold green--text"
                        v-if="cart.quantidade > 0 && calcularStock(cart) >= 1"
                      >
                        <span class="green--text font-weight-bolder">
                          restam
                          {{ calcularStock(cart) }}
                          <span v-if="calcularStock(cart) == 1"> item </span>
                          <span v-else> itens </span>
                        </span>
                      </h5>
                      <h5 class="font-weight-bold" v-else>
                        <span class="red--text">
                          Produto esgotado temporariamente
                        </span>
                      </h5>
                    </div>
                    <div class="col-5">
                      <h3>
                        {{ cart.designacao }}
                      </h3>
                      <h4 class="font-weight-medium">
                        {{ formatePrice(cart.pvp) }}
                        X
                        {{ cart.quantidade }}
                      </h4>
                      <h4 class="primary--text font-weight-bold">
                        {{ formatePrice(parseFloat(cart.subtotal)) }}
                        KZ
                      </h4>
                    </div>

                    <v-btn
                      @click="showDialogDeleteFromCart(cart)"
                      color="error"
                      rounded
                      text
                    >
                      <v-icon md>mdi-delete</v-icon>
                    </v-btn>
                  </div>
                  <v-row> </v-row>
                  <v-divider color="primary" />

                  <!-- <v-row v-if="loadingData">
                    <v-col cols="12">
                      <v-progress-linear
                        indeterminate
                        color="secondary"
                      ></v-progress-linear>
                    </v-col>
                  </v-row> -->
                </div>
              </div>

              <div v-if="carrinho.produtos.length == 0 && !overlay">
                <div
                  class="empty-cart-sidebar d-flex flex-column justify-center align-center mt-10"
                >
                  <v-avatar size="90" tile class="mb-8">
                    <img src="/assets/images/empty-cart.png" alt="" />
                  </v-avatar>
                  <p
                    v-if="!user"
                    class="grey--text text--darken-2 px-5 mx-10 text-center"
                  >
                    O Carrinho está vazio. Selecione pelo menos um produto.
                  </p>
                </div>
              </div>
              <!-- </v-container> -->
              <v-card-text v-if="carrinho.produtos.length > 0 && !overlay">
                <v-card-text name="web">
                  <v-row>
                    <div cols="12" md="6" class="text-right secondary--text">
                      <h3 class="font-weight-bold">
                        Total Produtos:
                        {{ formatePrice(carrinho.totalPagar) }}
                        KZ
                      </h3>
                    </div>
                  </v-row>

                  <v-row>
                    <div cols="12" md="6" class="text-right">
                      <h3 class="secondary--text font-weight-bold">
                        Total de IVA:
                        {{ formatePrice(carrinho.totalIva) }} KZ
                      </h3>
                    </div>
                  </v-row>
                  <h2
                    v-if="isWeb"
                    name="total-carrinho"
                    class="text-center font-weight-bold primary--text"
                  >
                    TOTAL:
                    {{ formatePrice(carrinho.totalPagar) }}
                    KZ
                  </h2>
                  <p v-if="isWeb" class="text-center">
                    Preço com IVA incluido à taxa em vigor
                  </p>

                  <v-row name="mobile-dados" v-if="!isWeb">
                    <v-col class="text-left" cols="12">
                      <h2
                        v-if="!isWeb"
                        name="total-carrinho"
                        class="text-center font-weight-bold primary--text"
                      >
                        TOTAL:
                        {{ formatePrice(carrinho.totalPagar) }}
                        KZ
                      </h2>
                      <p v-if="!isWeb" class="text-center">
                        Preço com IVA incluido à taxa em vigor
                      </p>
                    </v-col>
                  </v-row>
                  <v-alert
                    name="carrinho-update"
                    dismissible
                    v-if="dados"
                    dense
                    text
                    type="success"
                  >
                    {{ dados }}
                  </v-alert>
                </v-card-text>
              </v-card-text>
            </v-container>

            <v-card-actions>
              <v-row class="justify-right mb-4" v-if="isWeb">
                <v-btn
                  title="Continuar Comprando"
                  color="black"
                  :to="{ name: 'ProdutosEmDestaque' }"
                  text
                  outlined
                >
                  <v-icon>mdi-chevron-left</v-icon>
                  Continuar Comprando
                </v-btn>
                <v-spacer />
                <v-btn
                  outlined
                  class="mr-4"
                  @click="printProforma()"
                  title="Ver Fatura Proforma dos Produtos no Carrinho"
                  color="black mr-1 darken-1 white--text"
                >
                  Ver Proforma
                  <v-icon>mdi-file-pdf</v-icon>
                </v-btn>
                <v-btn
                  color="secondary darken-1"
                  title="Avançar com a Compra"
                  @click="
                    !token ? (passoInicial = 2) : showDialogFormaEntrega()
                  "
                >
                  SEGUINTE
                  <v-icon color="white">mdi-chevron-right</v-icon>
                </v-btn>
              </v-row>

              <v-row v-if="!isWeb">
                <v-col cols="12">
                  <v-btn
                    block
                    color="black"
                    :to="{ name: 'ProdutosEmDestaque' }"
                    text
                    outlined
                  >
                    <v-icon>mdi-chevron-left</v-icon>
                    Continuar Comprando
                  </v-btn>
                </v-col>
                <v-col cols="12">
                  <v-btn
                    outlined
                    @click="printProforma()"
                    title="Ver Fatura Proforma dos Produtos no Carrinho"
                    color="black"
                    class="mr-1 darken-1 white--text"
                    block
                  >
                    Ver Proforma
                    <v-icon>mdi-file-pdf</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="12">
                  <v-btn
                    block
                    color="secondary darken-1"
                    title="Avançar com a Compra"
                    @click="
                      !token ? (passoInicial = 2) : showDialogFormaEntrega()
                    "
                  >
                    SEGUINTE
                    <v-icon color="white">mdi-chevron-right</v-icon>
                  </v-btn>
                </v-col>

                <!-- <v-btn color="black" :to="{ name: 'ProdutosEmDestaque' }" text outlined>
                  <v-icon>mdi-chevron-left</v-icon>
                  Continuar Comprando
                </v-btn>
                <v-spacer />
                <v-btn outlined class="mr-4" @click="printProforma()" title="Ver Fatura Proforma dos Produtos no Carrinho" color="black mr-1 darken-1 white--text">
                  Ver Proforma
                  <v-icon>mdi-file-pdf</v-icon>
                </v-btn>
                <v-btn color="secondary darken-1" title="Avançar com a Compra" @click="
                    !token ? (passoInicial = 2) : showDialogFormaEntrega()
                  ">
                  SEGUINTE
                  <v-icon color="white">mdi-chevron-right</v-icon>
                </v-btn> -->
              </v-row>
            </v-card-actions>
          </v-stepper-content>

          <v-stepper-content name="stepper-2" step="2">
            <v-card class="mb-12">
              <v-container class="fill-height">
                <v-row align="center" class="text-center">
                  <v-col cols="12" md="6" v-if="!token">
                    <v-card class="elevation-8" width="380">
                      <div class="px-3 px-md-10 py-8">
                        <h3
                          class="mb-2 text-center secondary--text font-weight-bold"
                        >
                          INICIAR SESSÃO
                        </h3>
                        <h5
                          class="font-600 grey--text text--darken-3 text-sm mb-9 text-center"
                        >
                          Iniciar sessão com email,telefone e password
                        </h5>
                        <v-text-field
                          outlined
                          dense
                          hide-details
                          class="mb-4"
                          :rules="[
                            (v) =>
                              !!v ||
                              'É obrigatório informar o telefone ou email',
                          ]"
                          placeholder="Telefone ou email"
                          tile
                          v-model="auth.email"
                        ></v-text-field>
                        <v-text-field
                          outlined
                          dense
                          hide-details
                          placeholder="Minha Password"
                          class="mb-4"
                          :rules="[
                            (v) => !!v || 'É obrigatório informar a password',
                          ]"
                          :append-icon="
                            showpassword ? 'visibility_off' : 'visibility'
                          "
                          :type="showpassword ? 'text' : 'password'"
                          @click:append="showpassword = !showpassword"
                          v-model="auth.password"
                        />
                      </div>

                      <v-btn
                        block
                        color="secondary"
                        class="text-capitalize font-600"
                        >Iniciar sessão</v-btn
                      >
                    </v-card>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-card class="elevation-8" width="380">
                      <div class="px-3 px-md-10 py-8">
                        <h3
                          class="mb-2 text-center secondary--text font-weight-bold"
                        >
                          CRIAR CONTA
                        </h3>
                        <h5
                          class="font-600 grey--text text--darken-3 text-sm mb-9 text-center"
                        >
                          Criar conta apenas com Email,Telefone e Senha
                        </h5>
                        <v-text-field
                          outlined
                          dense
                          hide-details
                          class="mb-4"
                          :rules="[
                            (v) => !!v || 'É obrigatório informar o telefone',
                          ]"
                          placeholder="Telefone"
                          tile
                          v-model="user.telefone"
                        />
                        <v-text-field
                          outlined
                          dense
                          hide-details
                          class="mb-4"
                          :rules="[
                            (v) => !!v || 'É obrigatório informar o email',
                          ]"
                          placeholder="Email"
                          tile
                          v-model="user.email"
                        />
                        <v-text-field
                          outlined
                          dense
                          hide-details
                          placeholder="Minha Password"
                          class="mb-4"
                          :rules="[
                            (v) => !!v || 'É obrigatório informar a password',
                          ]"
                          :append-icon="
                            showpassword ? 'visibility_off' : 'visibility'
                          "
                          :type="showpassword ? 'text' : 'password'"
                          @click:append="showpassword = !showpassword"
                          v-model="user.password"
                        />
                        <v-text-field
                          outlined
                          dense
                          hide-details
                          placeholder="Minha Password"
                          class="mb-4"
                          :rules="[
                            (v) => !!v || 'É obrigatório informar a password',
                          ]"
                          :append-icon="
                            showpassword ? 'visibility_off' : 'visibility'
                          "
                          :type="showpassword ? 'text' : 'password'"
                          @click:append="showpassword = !showpassword"
                          v-model="auth.confirm_password"
                        />
                      </div>
                      <v-btn
                        block
                        color="secondary"
                        class="text-capitalize font-600"
                        >Criar conta</v-btn
                      >
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>

            <v-card-actions>
              <v-row class="justify-right mb-4">
                <v-spacer />
                <v-btn outlined @click="passoInicial = 1" text class="mr-2">
                  <v-icon>mdi-chevron-left</v-icon>
                  Voltar
                </v-btn>

                <v-btn color="secondary" @click="passoInicial = 3">
                  SEGUINTE
                  <v-icon color="white">mdi-chevron-right</v-icon>
                </v-btn>
              </v-row>
            </v-card-actions>
          </v-stepper-content>

          <v-stepper-content name="stepper-3 endereco a ser enviado" step="3">
            <!-- <v-form @submit="validateFormEnderecoEnvio()" ref="formEnderecoEnvio"> -->
            <v-card class="mb-12">
              <v-card-title class="secondary white--text justify-center">
                <div class="ml-2 text-h5 font-weight-light">
                  <v-icon md color="white">mdi-map-marker</v-icon>
                  ENDEREÇO DE ENVIO
                </div>
              </v-card-title>
              <v-container>
                <v-row class="mt-2">
                  <v-col cols="12">
                    <v-text-field
                      prepend-icon="mdi-account-box"
                      label="Nome Próprio"
                      outlined
                      v-model="user.name"
                      :rules="rulesFormEnderecoEnvio.name"
                    ></v-text-field>
                  </v-col>

                  <!-- <v-col cols="12" md="6">
                        <v-text-field
                          prepend-icon="mdi-account"
                          label="Apelido"
                          placeholder="Apelido"
                          outlined
                          v-model="cliente.nome"
                        ></v-text-field>
                      </v-col> -->
                </v-row>

                <v-row name="linha-2" style="margin-top: -30px">
                  <v-col cols="12" md="12">
                    <v-text-field
                      prepend-icon="mdi-map-marker"
                      label="Endereço"
                      placeholder="Endereço"
                      outlined
                      v-model="user.cliente.endereco"
                      :rules="rulesFormEnderecoEnvio.endereco"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row name="linha-2" style="margin-top: -30px">
                  <v-col cols="12" md="12">
                    <v-text-field
                      prepend-icon="mdi-map-marker-up"
                      label="Ponto de Referência"
                      placeholder="Ponto de Referência"
                      outlined
                      v-model="cliente.pontoReferenciaEntrega"
                      :rules="rulesFormEnderecoEnvio.pontoReferenciaEntrega"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row name="linha-3">
                  <v-col cols="12" md="6">
                    <v-text-field
                      prepend-icon="mdi-phone"
                      label="Telefone"
                      placeholder="923 00 11 22"
                      outlined
                      v-model="user.telefone"
                      :rules="rulesFormEnderecoEnvio.telefone"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field
                      prepend-icon="mdi-mail"
                      label="Email"
                      outlined
                      v-model="user.email"
                      :rules="rulesFormEnderecoEnvio.email"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row name="linha-4">
                  <v-col cols="12" md="6">
                    <v-autocomplete
                      prepend-icon="mdi-home"
                      label="Província"
                      @change="getMunicipiosByProvinciaId()"
                      outlined
                      item-text="designacao"
                      item-value="id"
                      :items="provincias"
                      v-model="cliente.provinciaEntrega"
                      :rules="[
                        (v) => !!v || 'É obrigatório informar a província',
                      ]"
                    />
                    <!-- <h4>{{ municipios }}</h4> -->
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-autocomplete
                      prepend-icon="mdi-home"
                      :loading="loadingComunas"
                      label="Município"
                      @change="getComunasByMunicipioId(selectedItem.id)"
                      outlined
                      item-text="designacao"
                      :items="municipios"
                      return-object
                      v-model="selectedItem"
                      :rules="[
                        (v) => !!v || 'É obrigatório informar o município',
                      ]"
                    />
                    <!-- <h4>
                      {{ selectedItem.id!=null && municipios && municipios.length>0 }}
                    </h4> -->
                  </v-col>
                </v-row>
                <v-row name="linha-4">
                  <v-col cols="12" md="6">
                    <v-autocomplete
                      :disabled="
                        !selectedItem.id && municipios && municipios.length > 0
                      "
                      prepend-icon="mdi-home"
                      label="Comuna"
                      outlined
                      item-text="designacao"
                      :items="comunas"
                      return-object
                      v-model="comunaItem"
                      :rules="[
                        (v) => !!v || 'É obrigatório selecionar a comuna',
                      ]"
                    />
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-card-text
                      v-if="
                        selectedItem.id != null &&
                        municipios &&
                        municipios.length > 0 &&
                        comunaItem.valor_entrega > 0
                      "
                    >
                      <h2
                        v-if="
                          comunaItem.valor_entrega > 0 &&
                          carrinho.cobrarTaxaEntrega == true
                        "
                      >
                        <v-icon>mdi-moped</v-icon>
                        ENTREGA:
                        <!-- {{ carrinho.cobrarTaxaEntrega }} -->
                        {{ formatePrice(comunaItem.valor_entrega) }} KZ
                      </h2>
                      <h2 v-else>
                        <v-icon>mdi-moped</v-icon>
                        ENTREGA GRÁTIS
                      </h2>
                    </v-card-text>
                  </v-col>
                </v-row>
                <v-row name="linha-5">
                  <v-col cols="12" md="12">
                    <v-text-field
                      prepend-icon="mdi-information-outline"
                      label="Se desejares incluir uma nota sobre a tua encomenda, por favor escreve no campo."
                      v-model="cliente.observacaoEntrega"
                      name="name"
                      type="textarea"
                      placeholder="Se desejares incluir uma nota sobre a tua encomenda, por favor escreve no campo."
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>

            <v-card-actions>
              <v-row class="justify-right mb-4">
                <v-spacer />

                <!-- this.dialogFormaEntrega = false; passoInicial = 1 -->
                <v-btn
                  outlined
                  text
                  @click="!token ? (passoInicial = 2) : (passoInicial = 1)"
                  class="mr-2"
                >
                  <v-icon>mdi-chevron-left</v-icon>
                  Voltar
                </v-btn>
                <v-btn
                  color="secondary"
                  @click="showDialogFormaPagamento()"
                  :disabled="
                    !(
                      selectedItem.id != null &&
                      municipios &&
                      municipios.length > 0 &&
                      comunaItem.valor_entrega > 0
                    )
                  "
                >
                  <!-- :disabled="validateFormEnderecoEnvio()" -->
                  SEGUINTE
                  <!-- @click="showDialogMetodosPagamento()" -->
                  <v-icon color="white">mdi-chevron-right</v-icon>
                </v-btn>
              </v-row>
            </v-card-actions>
            <!-- </v-form> -->
          </v-stepper-content>

          <!-- <v-stepper-content step="4" name="stepper-4">
                <v-card class="mb-12">
                  <v-card-title class="justify-center secondary white--text">
                    <div class="ml-2 text-h5 font-weight-light">
                      <v-icon lg color="white">mdi-moped</v-icon>
                      ENVIO
                    </div>
                  </v-card-title>

                  <v-container>
                    <h4>Escolhe uma opção de envio para este endereço</h4>
                    <v-data-table
                      v-model="selected"
                      :headers="headers"
                      :items="desserts"
                      :single-select="singleSelect"
                      item-key="name"
                      show-select
                      class="elevation-1"
                      hide-default-footer
                    >
                      <template v-slot:[`item.custo_de_entrega`]="{ item }">
                        <v-chip
                          class="ma-2 text-uppercase"
                          color="success"
                          v-if="item.custo_de_entrega == 0"
                        >
                          <v-icon left> done </v-icon>
                          GRÁTIS
                        </v-chip>
                        <v-chip class="ma-2 text-uppercase" color="secondary" v-else>
                          <v-icon left> info </v-icon>
                          {{ formatePrice(item.custo_de_entrega) }}
                          AKZ
                        </v-chip>
                      </template>
                    </v-data-table>
                  </v-container>
                </v-card>

                <v-card-actions>
                  <v-row class="justify-right mb-4">
                    <v-spacer />

                    <v-btn text @click="passoInicial = 3" class="mr-2">
                      <v-icon>mdi-chevron-left</v-icon>
                      Voltar
                    </v-btn>
                    <v-btn color="secondary" @click="showDialogMetodosPagamento()">
                      SEGUINTE
                      <v-icon color="white">mdi-chevron-right</v-icon>
                    </v-btn>
                  </v-row>
                </v-card-actions>
              </v-stepper-content> -->

          <v-stepper-content step="5" name="stepper-5">
            <v-card class="mb-12">
              <v-card-title class="secondary white--text justify-center">
                <div class="ml-2 text-h5 font-weight-light text-uppercase">
                  <v-icon lg color="white">mdi-credit-card</v-icon>
                  RESUMO
                </div>
              </v-card-title>

              <v-container>
                <div>
                  <v-card-actions>
                    <v-row class="justify-center">
                      <v-btn
                        class="mr-2"
                        outlined
                        @click="goBackFormaEntrega"
                        text
                        title="Voltar"
                      >
                        <v-icon>mdi-chevron-left</v-icon>
                        Voltar
                      </v-btn>
                      <v-btn
                        medium
                        title="Finalizar Compra"
                        color="secondary"
                        @click="showDialogFinalizarCompra()"
                      >
                        Finalizar Compra
                        <v-icon color="white">mdi-chevron-right</v-icon>
                      </v-btn>
                    </v-row>
                  </v-card-actions>
                </div>
              </v-container>

              <v-container class="mb-60">
                <v-container
                  name="confirmacao-dados"
                  v-if="carrinho.produtos.length > 0 && !overlay"
                >
                  <v-card
                    style="border: 1px solid rgb(178, 183, 179)"
                    id="rounded-card"
                    class="pa-1"
                  >
                    <v-card
                      style="border: 1px solid rgb(178, 183, 179)"
                      v-if="!pagamentoComCartao"
                    >
                      <v-col cols="12">
                        <span class="subtitle-1 text-uppercase">
                          ENTIDADE BANCÁRIA:
                        </span>
                        <span class="subtitle-1 font-weight-bold">
                          {{ pagamento.banco.designacao }}({{
                            pagamento.banco.sigla
                          }})
                        </span>
                      </v-col>
                    </v-card>

                    <v-card
                      style="border: 1px solid rgb(178, 183, 179)"
                      v-if="!pagamentoComCartao"
                    >
                      <v-col cols="12">
                        <span class="subtitle-1 text-uppercase">
                          NÚMERO DE CONTA:
                        </span>
                        <span class="subtitle-1 font-weight-bold">
                          {{ pagamento.banco.coordernada_bancaria.num_conta }}
                        </span>
                      </v-col>
                    </v-card>

                    <v-card
                      style="border: 1px solid rgb(178, 183, 179)"
                      v-if="!pagamentoComCartao"
                    >
                      <v-col cols="12">
                        <span class="subtitle-1 text-uppercase"> IBAN: </span>
                        <span class="subtitle-1 font-weight-bold">
                          {{ pagamento.banco.coordernada_bancaria.iban }}
                        </span>
                      </v-col>
                    </v-card>

                    <v-card
                      style="border: 1px solid rgb(178, 183, 179)"
                      v-if="!pagamentoComCartao"
                    >
                      <v-col cols="12">
                        <span class="subtitle-1 text-uppercase">
                          TITULAR DA CONTA:
                        </span>
                        <span class="subtitle-1 font-weight-bold">
                          {{ pagamento.banco.titular }}
                        </span>
                      </v-col>
                    </v-card>

                    <v-card style="border: 1px solid rgb(178, 183, 179)">
                      <v-col cols="12">
                        <span class="subtitle-1"> FORMA DE PAGAMENTO: </span>
                        <span class="subtitle-1 font-weight-bold">
                          {{
                            pagamentoComCartao
                              ? "CARTÃO MUTUE"
                              : forma_de_pagamento.descricao
                          }}
                          <!-- <span class="subtitle-1 font-weight-bold">()</span> -->
                        </span>
                      </v-col>
                    </v-card>
                    <div v-if="pagamentoComCartao">
                      <v-card style="border: 1px solid rgb(178, 183, 179)">
                        <v-col cols="12">
                          <span class="subtitle-1">
                            SALDO ATUAL NO CARTÃO:
                          </span>
                          <span class="subtitle-1 font-weight-bold">
                            {{ formatePrice(cartao.saldo) }} KZ
                          </span>
                        </v-col>
                      </v-card>

                      <v-card style="border: 1px solid rgb(178, 183, 179)">
                        <v-col cols="12">
                          <span class="subtitle-1">
                            VALOR A DEBITAR NO CARTÃO:
                          </span>
                          <span class="subtitle-1 font-weight-bold red--text">
                            {{
                              formatePrice(
                                calcularTotalComEnvio(carrinho.totalPagar)
                              )
                            }}
                            KZ
                          </span>
                        </v-col>
                      </v-card>

                      <v-card style="border: 1px solid rgb(178, 183, 179)">
                        <v-col cols="12">
                          <span class="subtitle-1">
                            SALDO FINAL NO CARTÃO:
                          </span>
                          <span class="subtitle-1 font-weight-bold red--text">
                            {{
                              formatePrice(
                                cartao.saldo -
                                  calcularTotalComEnvio(carrinho.totalPagar)
                              )
                            }}
                            KZ
                          </span>
                        </v-col>
                      </v-card>
                    </div>

                    <v-card style="border: 1px solid rgb(178, 183, 179)">
                      <v-col cols="12">
                        <span class="subtitle-1"> VALOR PAGO: </span>
                        <span class="subtitle-1 font-weight-bold">
                          {{ formatePrice(carrinho.totalPagar) }} KZ
                        </span>
                      </v-col>
                    </v-card>

                    <v-card style="border: 1px solid rgb(178, 183, 179)">
                      <v-col cols="12">
                        <span class="subtitle-1 text-uppercase">
                          Recepção da encomenda:
                        </span>
                        <span
                          class="subtitle-1 font-weight-bold"
                          v-if="cliente.tipoEntregaId == 1"
                        >
                          EM CASA
                        </span>
                        <span class="subtitle-1 font-weight-bold" v-else>
                          NA LOJA
                        </span>
                      </v-col>
                    </v-card>

                    <v-card
                      style="border: 1px solid rgb(178, 183, 179)"
                      v-if="cliente.tipoEntregaId == 1"
                    >
                      <v-col cols="12">
                        <span class="subtitle-1 text-uppercase">
                          Endereço de entrega:
                        </span>
                        <span class="subtitle-1 font-weight-bold">
                          {{ selectedItem.designacao }} ,{{
                            comunaItem.designacao
                          }}
                        </span>
                      </v-col>
                    </v-card>

                    <v-card
                      style="border: 1px solid rgb(178, 183, 179)"
                      v-if="cliente.tipoEntregaId == 1"
                    >
                      <v-col cols="12">
                        <span class="subtitle-1 text-uppercase">
                          Custo de envio:
                        </span>
                        <span
                          class="subtitle-1 font-weight-bold"
                          v-if="carrinho.cobrarTaxaEntrega == true"
                        >
                          {{ formatePrice(comunaItem.valor_entrega) }} KZ
                        </span>
                        <span class="subtitle-1 font-weight-bold" v-else>
                          Grátis
                        </span>
                      </v-col>
                    </v-card>

                    <v-card style="border: 1px solid rgb(178, 183, 179)">
                      <v-col cols="12">
                        <span class="subtitle-1 text-uppercase">
                          TOTAL A PAGAR:
                        </span>
                        <span class="subtitle-1 font-weight-bold">
                          {{
                            formatePrice(
                              calcularTotalComEnvio(carrinho.totalPagar)
                            )
                          }}
                          KZ
                        </span>
                      </v-col>
                    </v-card>

                    <v-card
                      style="border: 1px solid rgb(178, 183, 179)"
                      v-if="cliente.tipoEntregaId == 1"
                    >
                      <v-col cols="12">
                        <span class="subtitle-1 text-uppercase"> NOTA: </span>
                        <span class="subtitle-1 red--text font-weight-bold">
                          A sua encomenda será processada após recebermos e
                          validarmos o pagamento da mesma.
                        </span>
                      </v-col>
                    </v-card>

                    <v-card style="border: 1px solid rgb(178, 183, 179)">
                      <v-col cols="12 text-center">
                        <h2
                          name="total-com-entrega"
                          class="text-center font-weight-bold error--text"
                        >
                          TOTAL:
                          {{
                            formatePrice(
                              calcularTotalComEnvio(carrinho.totalPagar)
                            )
                          }}
                          KZ
                        </h2>
                        (Preço com IVA incluido à taxa em vigor)
                      </v-col>
                    </v-card>
                  </v-card>
                </v-container>

                <div v-if="carrinho.produtos.length > 0">
                  <v-card elevation="0" name="list-products" id="rounded-card">
                    <v-col cols="12">
                      <v-card
                        style="border: 1px solid rgb(178, 183, 179)"
                        id="rounded-card"
                        elevation="0"
                        v-for="cart in carrinho.produtos"
                        :key="cart.id"
                        class="mb-1"
                      >
                        <v-card-text
                          class="ps-6 d-flex justify-space-between align-center flex-wrap"
                        >
                          <v-list-item>
                            <v-list-item-avatar
                              size="80"
                              :title="cart.designacao"
                              rounded
                            >
                              <v-img
                                :alt="cart.designacao"
                                :src="SERVER + cart.imagem_produto"
                              ></v-img>
                            </v-list-item-avatar>

                            <v-list-item-content>
                              <v-list-item-title
                                style="max-width: 700px"
                                class="font-weight-bold text-uppercase d-inline-block text-truncate"
                                >{{ cart.designacao }}</v-list-item-title
                              >
                              <v-list-item-subtitle
                                class="text-subtitle2 font-weight-medium"
                              >
                                <h4 class="font-weight-medium">
                                  {{ formatePrice(cart.pvp) }}
                                  X
                                  {{ cart.quantidade }}
                                </h4>
                              </v-list-item-subtitle>

                              <v-list-item-subtitle>
                                <h4 class="black--text font-weight-bold">
                                  {{ formatePrice(parseFloat(cart.subtotal)) }}
                                  AKZ
                                </h4>
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-card>
                </div>

                <div v-if="carrinho.produtos.length == 0 && !overlay">
                  <div
                    class="empty-cart-sidebar d-flex flex-column justify-center align-center mt-10"
                  >
                    <v-avatar size="90" tile class="mb-8">
                      <img src="/assets/images/empty-cart.png" alt="" />
                    </v-avatar>
                    <p
                      v-if="!user"
                      class="grey--text text--darken-2 px-5 mx-10 text-center"
                    >
                      O Carrinho está vazio. Selecione pelo menos um produto.
                    </p>
                  </div>
                </div>
              </v-container>
            </v-card>

            <v-row v-if="alert.type == 'error'">
              <v-col>
                <v-alert outlined type="error" dismissible dense>
                  Ocorreu um erro ao processar essa compra. Tente de novo.
                </v-alert>
              </v-col>
            </v-row>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </div>
    <v-dialog
      scrollable
      v-model="dialogUpdateEnderecoFaturacao"
      max-width="800"
      name="dialog-endereco-de-faturacao"
      persistent
    >
      <v-card>
        <v-card-title class="secondary white--text">
          Endereço de Faturação
          <v-spacer />
          <v-btn
            icon
            text
            elevation="1"
            small
            @click="dialogUpdateEnderecoFaturacao = false"
          >
            <v-icon class="secondary white--text">close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="mt-0" color="secondary" />
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  prepend-icon="person"
                  label="Nome Próprio"
                  placeholder="Nome Próprio"
                  outlined
                  v-model="endereco.nome_proprio"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  prepend-icon="mdi-account"
                  label="Apelido"
                  placeholder="Apelido"
                  outlined
                  v-model="endereco.apelido"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row name="linha-2" style="margin-top: -30px">
              <v-col cols="12" md="12">
                <v-text-field
                  prepend-icon="location_on"
                  label="Endereço"
                  placeholder="Endereço"
                  outlined
                  v-model="endereco.designacao"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row name="linha-2" style="margin-top: -30px">
              <v-col cols="12" md="12">
                <v-text-field
                  prepend-icon="location_on"
                  label="Ponto de Referência"
                  placeholder="Ponto de Referência"
                  outlined
                  v-model="endereco.pontoReferencia"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row name="linha-3">
              <v-col cols="12" md="6">
                <v-text-field
                  prepend-icon="phone"
                  label="Telefone principal"
                  placeholder="923 00 11 22"
                  outlined
                  v-model="endereco.telefone1"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-autocomplete
                  prepend-icon="home"
                  label="Pais de Residência"
                  outlined
                  item-text="designacao"
                  item-value="id"
                  :items="paises"
                  v-model="endereco.pais_id"
                  :rules="[(v) => !!v || 'É obrigatório informar a província']"
                />
              </v-col>
            </v-row>

            <v-row name="linha-4">
              <v-col cols="12" md="6">
                <v-autocomplete
                  prepend-icon="home"
                  label="Provincia de Residência"
                  outlined
                  item-text="designacao"
                  item-value="id"
                  :items="provincias"
                  v-model="endereco.provincia_id"
                  :rules="[(v) => !!v || 'É obrigatório informar a província']"
                />
              </v-col>

              <v-col cols="12" md="6">
                <v-autocomplete
                  prepend-icon="home"
                  label="Municipio"
                  outlined
                  item-text="designacao"
                  item-value="id"
                  :items="municipios"
                  v-model="endereco.municipio_id"
                  :rules="[(v) => !!v || 'É obrigatório informar a província']"
                />
              </v-col>
            </v-row>

            <v-row name="linha-5">
              <v-col cols="12" md="12">
                <v-text-field
                  prepend-icon="info"
                  label="Se desejares incluir uma nota sobre a tua encomenda, por favor escreve no campo."
                  name="name"
                  type="textarea"
                  placeholder="Se desejares incluir uma nota sobre a tua encomenda, por favor escreve no campo."
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary darken-1"
            text
            @click="dialogUpdateEnderecoEntrega = false"
          >
            Salvar
          </v-btn>
          <v-spacer class="hidden-sm-and-up" />
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      name="dialog-eliminar-tudo-do-carrinho-da-BD"
      v-model="dialogDeleteAllFromCart"
      max-width="500"
    >
      <v-card>
        <v-divider />
        <v-card-text>
          <div class="text-center mt-4">
            <v-icon color="error" size="60px">mdi-information-outline</v-icon>
          </div>
          <v-row class="text-left mt-2 text-center text-h6 primary--text">
            <span>
              Tem a certeza que pretende eliminar o(s)
              <span class="font-weight-bold"> {{ cart.length }}</span>
              produto(s) do carrinho?
            </span>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="secondary darken-1"
            @click="dialogDeleteAllFromCart = false"
            outlined
          >
            Mudei de ideia
          </v-btn>
          <v-btn color="error darken-1" @click="emptyStorageCart()">
            Sim, Esvaziar carrinho <v-icon>mdi-delete</v-icon>
          </v-btn>
          <v-spacer class="hidden-sm-and-up" />
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      name="dialog-eliminar-produto-do-carrinho-na-BD"
      v-model="dialogDeleteFromCartDaBD"
      max-width="500"
    >
      <!-- <v-card>
        <v-divider />
        <v-card-text>
          <div class="text-center mt-4">
            <v-icon color="error" size="60px">mdi-information-outline</v-icon>
          </div>
          <v-row class="text-left mt-2 text-center text-h6 error--text">
            <span>
              Tem a certeza que pretende eliminar
              <span class="font-weight-bold"> {{ produto.designacao }}</span> do
              carrinho?
            </span>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="error darken-1" @click="dialogDeleteFromCartDaBD = false" outlined>
            Mudei de ideia
          </v-btn>
          <v-btn color="error darken-1" @click="removeFromCart(produto)">
            Sim, Eliminar <v-icon>mdi-delete</v-icon>
          </v-btn>
          <v-spacer class="hidden-sm-and-up" />
        </v-card-actions>
      </v-card> -->

      <v-card>
        <v-card-text class="text-center">
          <div class="text-center mt-10">
            <v-icon color="error" size="60px">mdi-information-outline</v-icon>
          </div>
          <v-row class="text-left mt-2 text-center text-h6 error--text">
            <span>
              Tem a certeza que pretende eliminar
              <span class="font-weight-bold"> {{ produto.designacao }}</span> do
              carrinho?
            </span>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            color="error"
            class="ml-2"
            outlined
            dense
            text
            @click="dialogDeleteFromCartDaBD = false"
          >
            Sair
          </v-btn>
          <v-btn
            color="error"
            @click="removeFromCart(produto)"
            class="white--text ml-2"
            dense
          >
            Sim
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      name="dialog-eliminar-do-carrinho-local"
      v-model="dialogDeleteFromCart"
      max-width="500"
    >
      <v-card>
        <v-divider />
        <v-card-text>
          <div class="text-center mt-4">
            <v-icon color="error" size="60px">mdi-information-outline</v-icon>
          </div>
          <v-row class="text-left mt-2 text-center text-h6 primary--text">
            <span>
              Tem a certeza que pretende eliminar
              <span class="font-weight-bold"> {{ produto.designacao }}</span> do
              carrinho?
            </span>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="secondary darken-1"
            @click="dialogDeleteFromCart = false"
            outlined
          >
            Mudei de ideia
          </v-btn>
          <v-btn color="error darken-1" @click="removeFromStorageCart(produto)">
            Sim, Eliminar <v-icon>mdi-delete</v-icon>
          </v-btn>
          <v-spacer class="hidden-sm-and-up" />
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      scrollable
      v-model="dialogPagamentoCartao"
      name="dialog-pagamento-cartao"
      max-width="800"
      persistent
    >
      <v-card v-if="!loading">
        <v-card-title class="secondary white--text">
          PAGAMENTO POR CARTÃO
          <v-spacer />
          <v-btn
            color="white darken-1"
            text
            rounded
            @click="dialogPagamentoCartao = false"
          >
            <v-icon color="white" size="30px">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="mt-0" color="secondary" />
        <v-card-text>
          <v-container>
            <v-row class="mt-2 justify-center">
              <v-col cols="6" md="6" class="justify-center">
                <v-hover v-slot:default="{ hover }" open-delay="200">
                  <v-card
                    title="Cartão Mutue"
                    :elevation="hover ? 16 : 2"
                    class="mx-auto"
                    max-width="280"
                    style="border-radius: 22px"
                  >
                    <div class="justify-center">
                      <center>
                        <v-img
                          style="width: 100%; height: 100%; object-fit: cover"
                          src="@/assets/images/cartao/cartao.jpg"
                        >
                        </v-img>
                      </center>
                    </div>
                    <v-divider class="mx-4" />
                  </v-card>
                </v-hover>
              </v-col>
              <v-card-text>
                <v-row align="center" class="mx-0">
                  <v-row v-if="user.cartaoCliente != null">
                    <v-col cols="6">
                      <span class="text-h6 black--text">
                        CARTÃO: {{ cartao.numeroCartao }}
                      </span>
                    </v-col>
                    <v-col cols="6">
                      <span class="text-h6 black--text">
                        DATA: {{ cartao.dataValidade }}
                      </span>
                    </v-col>
                    <v-col cols="6">
                      <span class="text-h6 black--text">
                        SALDO:
                        <span class="text-h6 font-weight-bold">
                          {{ formatePrice(cartao.saldo) }} AKZ
                        </span>
                      </span>
                    </v-col>
                    <!-- <h1>
                      {{ cartao }}
                    </h1> -->
                    <v-col cols="6">
                      <span class="text-h6 red--text">
                        TOTAL A PAGAR:
                        <span class="font-weight-bold text-h6">
                          {{
                            formatePrice(
                              calcularTotalComEnvio(carrinho.totalPagar)
                            )
                          }}
                          AKZ</span
                        >
                      </span>
                    </v-col>
                  </v-row>
                  <!-- <v-col cols="12" md="6">
                    <v-text-field v-model="cartao.numeroCartao" prepend-icon="mdi-clipboard-text" v-mask="'#### #### #### ####'" outlined label="Número de Cartão"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field v-model="cartao.dataValidade" type="date" prepend-icon="mdi-clipboard-text" label="Data de Validade" placeholder="01-10-2023" outlined></v-text-field>
                  </v-col> -->
                  <v-col cols="12" name="add-new-cartao">
                    <!--v-if="checkSaldoCartaoNegativo(cartao, carrinho)" -->
                    <v-expansion-panels
                      elevation="0"
                      v-if="user.cartaoCliente == null"
                    >
                      <v-expansion-panel>
                        <v-expansion-panel-header class="primary--text">
                          {{
                            user.cartaoCliente != null
                              ? "ADICIONAR OUTRO CARTÃO"
                              : "ADICIONAR NOVO CARTÃO"
                          }}
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-col cols="12" md="12">
                            <v-text-field
                              v-model="cartaoNovo.numeroCartao"
                              prepend-icon="mdi-clipboard-text"
                              v-mask="'#### #### #### ####'"
                              outlined
                              label="Número de Cartão"
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" md="12">
                            <v-text-field
                              v-model="cartaoNovo.dataValidade"
                              type="date"
                              prepend-icon="mdi-clipboard-text"
                              label="Data de Validade"
                              placeholder="01-10-2023"
                              outlined
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-alert
                              border="top"
                              color="red"
                              dismissible
                              v-if="cartaoError"
                              type="error"
                              >{{ cartaoError }}</v-alert
                            >
                          </v-col>
                          <v-col cols="12" md="12" @click="checkNovoCartao()">
                            <v-btn color="secondary">ADICIONAR CARTÃO</v-btn>
                          </v-col>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-col>
                </v-row>
                <v-row v-if="user.cartaoCliente == null">
                  <v-col cols="12">
                    <v-alert dense outlined dismissible type="error">
                      <strong> Não existe Cartão associado a tua conta</strong
                      >Tente inserir um outro Cartão ou seleciona outro Método
                      de Pagamento.
                    </v-alert>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-row>
                <!-- <h5>CARTAO: {{parseInt (cartao.saldo) }} > {{parseInt(carrinho.totalPagar) }} </h5> -->
                <!-- <h5> TOTAL: {{ carrinho.totalPagar }}</h5>
                <h1>{{ checkSaldoCartaoNegativo(cartao,carrinho) }}</h1> -->
              </v-row>

              <v-row
                v-if="
                  checkSaldoCartaoNegativo(cartao, carrinho) &&
                  user.cartaoCliente != null
                "
              >
                <v-col cols="12">
                  <v-alert dense outlined dismissible type="error">
                    <strong
                      >O saldo no Cartão( {{ formatePrice(cartao.saldo) }} AKZ )
                      é insuficiente</strong
                    >
                    para finalizar essa compra no valor total de
                    <strong
                      >{{
                        formatePrice(calcularTotalComEnvio(carrinho.totalPagar))
                      }}
                      AKZ.</strong
                    >
                    Tente inserir um outro Cartão ou seleciona outro Método de
                    Pagamento.
                  </v-alert>
                </v-col>
              </v-row>
              <!-- {{checkSaldoCartaoNegativo()}} -->
              <v-card-actions class="text-right">
                <v-spacer></v-spacer>
                <v-btn
                  color="secondary darken-1"
                  @click="dialogPagamentoCartao = false"
                  outlined
                >
                  Sair
                </v-btn>
                <v-btn
                  color="secondary darken-1"
                  :disabled="checkSaldoCartaoNegativo(cartao, carrinho)"
                  @click="setPagamento()"
                >
                  Avançar
                </v-btn>
                <v-spacer class="hidden-sm-and-up" />
              </v-card-actions>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      scrollable
      v-model="dialogFormaPagamento"
      name="dialog-forma-pagamento"
      max-width="800"
      persistent
    >
      <v-card v-if="!loading">
        <v-card-title class="secondary white--text">
          FORMAS DE PAGAMENTO
          <v-spacer />
          <v-btn
            color="white darken-1"
            text
            rounded
            @click="dialogFormaPagamento = false"
          >
            <v-icon color="white" size="30px">mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-divider class="mt-0" color="secondary" />
        <v-card-text>
          <v-container>
            <v-row class="mt-2">
              <v-col cols="12" class="text-center">
                <h3>
                  Caro(a) {{ user.name }}, como deseja pagar por esse produto?
                </h3>
              </v-col>

              <!-- <v-col cols="6" md="6">
                <v-hover v-slot:default="{ hover }" open-delay="200">
                  <v-card
                    disabled
                    title="Pagamento com Cartão MUTUE"
                    :elevation="hover ? 16 : 2"
                    class="mx-auto text-center"
                    max-width="280"
                    style="border-radius: 22px"
                    @click="showDialogPagamentoCartao()"
                  >
                    <div class="text-center">
                      <v-icon color="secondary" size="120px">
                        mdi-credit-card
                      </v-icon>
                    </div>

                    <div
                      class="primary--text text-uppercase text-center font-weight-bold"
                    >
                      CARTÃO MUTUE
                    </div>
                    <v-card-text>
                      <v-row align="center" class="mx-0"> </v-row>
                    </v-card-text>
                    <v-divider class="mx-4" />
                  </v-card>
                </v-hover>
              </v-col> -->

              <v-col cols="12" md="6">
                <v-hover v-slot:default="{ hover }" open-delay="200">
                  <v-card
                    title="Pagamento pelo Sistema Bancário: Transferencia Bancária, Multicaixa ou Depósito"
                    :elevation="hover ? 16 : 2"
                    class="mx-auto text-center"
                    max-width="280"
                    style="border-radius: 22px"
                    @click="showDialogMetodosPagamento()"
                  >
                    <div class="text-center">
                      <v-icon color="secondary" size="120px"> mdi-bank </v-icon>
                    </div>

                    <div
                      class="primary--text text-uppercase text-center font-weight-bold"
                    >
                      SISTEMA BANCÁRIO
                    </div>
                    <v-card-text>
                      <v-row align="center" class="mx-0"> </v-row>
                    </v-card-text>
                    <v-divider class="mx-4" />
                  </v-card>
                </v-hover>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      scrollable
      v-model="dialogFormaEntrega"
      max-width="800"
      name="dialog-forma-entrega"
      persistent
    >
      <v-card v-if="!loading">
        <v-card-title class="secondary white--text">
          <div class="text-h6" v-if="isWeb">
            LOCAL DE RECEPÇÃO DAS ENCOMENDAS
          </div>
          <div class="text-h6" v-else>LOCAL DE ENTREGA</div>
          <v-spacer />
          <v-btn
            color="white darken-1"
            text
            rounded
            @click="dialogFormaEntrega = false"
          >
            <v-icon color="white" size="30px">mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-divider class="mt-0" color="secondary" />
        <v-card-text>
          <v-container>
            <v-row class="mt-2">
              <v-col cols="12" class="text-center">
                <h3>
                  Caro(a) {{ user.name }}, onde deseja receber a sua encomenda?
                </h3>
              </v-col>

              <v-col
                cols="12"
                md="6"
                sm="12"
                v-for="item in formasEntregas"
                :key="item.id"
              >
                <v-hover v-slot:default="{ hover }" open-delay="200">
                  <v-card
                    :title="item.designacao"
                    :elevation="hover ? 16 : 2"
                    class="mx-auto text-center"
                    max-width="280"
                    style="border-radius: 22px"
                    @click="getFormaEntrega(item.id)"
                  >
                    <div class="text-center">
                      <v-icon color="secondary" size="120px">
                        {{ item.icon }}</v-icon
                      >
                    </div>
                    <div
                      class="primary--text text-uppercase text-center font-weight-bold"
                    >
                      {{ item.designacao }}
                    </div>
                    <v-card-text>
                      <v-row align="center" class="mx-0"> </v-row>
                    </v-card-text>
                    <v-divider class="mx-4" />
                  </v-card>
                </v-hover>
              </v-col>

              <v-col class="ml-6 text-justify" cols="12">
                <span>
                  Caro cliente, a nossa loja tem duas formas de Entrega de
                  Produtos. <br />
                  <span class="font-weight-bold"> Em Casa</span>: Nós levamos o
                  produto até o teu endereço cobrando uma taxa de entrega de
                  acordo a tua Localização.
                  <br />
                  <span class="font-weight-bold"> Na Loja</span>: O cliente
                  desloca-se até uma das nossas lojas mais próximas e faz o
                  levantamento do produto com a fatura que comprova que o
                  pagamento foi aceite.
                </span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      scrollable
      v-model="dialogMetodosPagamento"
      max-width="800"
      name="dialog-metodos-pagamento"
      persistent
    >
      <v-card v-if="!loading">
        <!--  DADOS DE PAGAMENTO|  -->
        <v-card-title class="secondary white--text">
          TOTAL A PAGAR:
          {{ formatePrice(carrinho.totalPagar) }}
          KZ
        </v-card-title>
        <v-divider class="mt-0" color="secondary" />
        <v-card-text>
          <v-container>
            <v-row class="mt-2">
              <v-col cols="12">
                <v-autocomplete
                  prepend-icon="mdi-bank"
                  label="Banco"
                  outlined
                  item-text="sigla"
                  item-value="id"
                  :items="banco"
                  @change="getBanco(banco)"
                  v-model="pagamento.banco_id"
                />
              </v-col>

              <v-card
                id="rounded-card"
                elevation="0"
                style="margin-bottom: 10px"
              >
                <div v-if="pagamento.banco.designacao">
                  <v-card
                    elevation="0"
                    style="border: 1px solid rgb(178, 183, 179)"
                  >
                    <v-col cols="12">
                      <span class="subtitle-1 text-capitalize">
                        ENTIDADE BANCÁRIA:
                      </span>
                      <span class="subtitle-1 font-weight-bold">
                        {{ pagamento.banco.designacao }}
                      </span>
                    </v-col>
                  </v-card>

                  <v-card
                    elevation="0"
                    style="border: 1px solid rgb(178, 183, 179)"
                  >
                    <v-col cols="12">
                      <span class="subtitle-1 text-capitalize"> Iban: </span>
                      <span class="subtitle-1 font-weight-bold">
                        {{ pagamento.banco.coordernada_bancaria.iban }}
                      </span>
                    </v-col>
                  </v-card>

                  <v-card
                    elevation="0"
                    style="border: 1px solid rgb(178, 183, 179)"
                  >
                    <v-col cols="12">
                      <span class="subtitle-1 text-capitalize"> Conta: </span>
                      <span class="subtitle-1 font-weight-bold">
                        {{ pagamento.banco.coordernada_bancaria.num_conta }}
                      </span>
                    </v-col>
                  </v-card>

                  <v-card
                    elevation="0"
                    style="border: 1px solid rgb(178, 183, 179)"
                  >
                    <v-col cols="12">
                      <span class="subtitle-1 text-capitalize"> Titular: </span>
                      <span class="subtitle-1 font-weight-bold">
                        {{ pagamento.banco.titular }}
                      </span>
                    </v-col>
                  </v-card>
                </div>
                <v-card
                  elevation="0"
                  style="border: 1px solid rgb(178, 183, 179)"
                >
                  <v-col cols="12">
                    <span class="subtitle-1 text-capitalize">
                      Total a Pagar:
                    </span>
                    <span class="subtitle-1 font-weight-bold red--text">
                      {{ formatePrice(carrinho.totalPagar) }} KZ ({{
                        carrinho.total_extenso
                      }})
                    </span>
                  </v-col>
                </v-card>
              </v-card>

              <!-- <v-col cols="12" class="ml-6" v-if="pagamento.banco.designacao">
                <p>
                  ENTIDADE BANCÁRIA: <b> {{ pagamento.banco.designacao }}</b>
                </p>
                <p>
                  IBAN:
                  <b> {{ pagamento.banco.coordernada_bancaria.iban }}</b>
                </p>
                <p>
                  CONTA:
                  <b> {{ pagamento.banco.coordernada_bancaria.num_conta }}</b>
                </p>

                <p>
                  TITULAR DA CONTA:
                  <b> {{ pagamento.banco.titular }} </b>
                </p>
              </v-col>

              <v-col class="ml-6 font-weight-bold red--text" cols="12">
                <span class="text-h6">
                  TOTAL A PAGAR:
                {{ formatePrice(carrinho.totalPagar) }} KZ ({{
                    carrinho.total_extenso
                  }})
                </span>
              </v-col> -->

              <v-col cols="12">
                <v-autocomplete
                  prepend-icon="mdi-cash"
                  :label="
                    'Como deseja Pagar ' +
                    'os ' +
                    formatePrice(carrinho.totalPagar) +
                    ' KZ?'
                  "
                  outlined
                  @change="reloadFormaPagamento(pagamento.forma_pagamento_id)"
                  item-text="descricao"
                  item-value="id"
                  :items="formasDePagamento"
                  v-model="pagamento.forma_pagamento_id"
                />
              </v-col>
            </v-row>

            <v-row name="Transferencia" v-if="pagamento.forma_pagamento_id">
              <v-col cols="12" md="6">
                <v-file-input
                  prepend-icon="mdi-file"
                  show-size
                  label="Comprovativo"
                  v-model="comprovativoBancario"
                  name="comprovativoBancario"
                  ref="fileInput"
                  outlined
                  @change="handleFileChange"
                >
                </v-file-input>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  prepend-icon="mdi-calendar"
                  :rules="[dateValidation]"
                  label="Data de Pagamento"
                  v-model="pagamento.dataPagamentoBanco"
                  outlined
                  type="date"
                ></v-text-field>
              </v-col>

              <v-col cols="12" v-if="message.length > 0">
                <v-alert dense dismissible outlined type="error">
                  {{ message }}
                </v-alert>
              </v-col>
              <!-- <v-col cols="12" v-if="message = '' && dataValida ">
                <v-alert type="success" dismissible>
                  Comprovativo enviado está num dos formatos válidos:PNG,JPG ou PDF
                </v-alert> -->
              <!-- <h6> {{  dataValida && !message && comprovativoBancario!='' }}</h6> -->
              <!-- <h6> {{  dataValida }} {{  !message }} {{ comprovativoBancario!='' }} </h6> -->
              <!-- </v-col> -->
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary darken-1"
            @click="dialogMetodosPagamento = false"
            outlined
          >
            Sair
          </v-btn>
          <v-btn
            color="secondary darken-1"
            v-if="dataValida && !message && comprovativoBancario != ''"
            :disabled="
              !pagamento.forma_pagamento_id &&
              message.length <= 0 &&
              comprovativoBancario == false
            "
            @click="setPagamento()"
          >
            Avançar
          </v-btn>
          <v-spacer class="hidden-sm-and-up" />
        </v-card-actions>
      </v-card>
      <v-card v-else>
        <div class="text-center mt-12">
          <v-progress-circular size="20" indeterminate color="secondary" />
        </div>
      </v-card>
    </v-dialog>

    <v-dialog
      name="dialog-finalizar-compra"
      v-model="dialogFinalizarCompra"
      max-width="350"
    >
      <v-card>
        <v-card-text class="text-center">
          <v-icon color="warning" class="mt-2" size="50"
            >mdi-information-outline</v-icon
          >
          <h6 class="mt-2">Pretende realmente Finalizar a compra?</h6>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            color="error"
            class="ml-2"
            outlined
            dense
            text
            @click="dialogFinalizarCompra = false"
          >
            Sair
          </v-btn>
          <v-btn
            color="green"
            class="white--text ml-2"
            dense
            @click="setFinalizarCompra()"
          >
            Sim
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      name="dialog-pagamento-suspenso"
      v-model="dialogPagamentoSuspenso"
      max-width="320"
      persistent
    >
      <v-card>
        <v-card-text class="text-center">
          <v-icon color="warning" class="mt-2" size="50"
            >mdi-information-outline</v-icon
          >
          <h4 class="mt-2">
            {{ message }}
          </h4>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            color="warning"
            dense
            outlined
            text
            @click="dialogPagamentoSuspenso = false"
          >
            Sair
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      name="dialog-logout-sucesso"
      v-model="dialogPagamentoEmAnalise"
      max-width="320"
      persistent
    >
      <v-card>
        <v-card-text class="text-center">
          <v-icon color="warning" class="mt-2" size="50"
            >mdi-check-circle</v-icon
          >
          <h6 class="mt-2">
            O seu Pagamento está em Análise. Entraremos em contacto consigo o
            mais rápido possivel.
          </h6>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            color="warning"
            dense
            outlined
            text
            @click="redirectToPagamentos"
          >
            <!-- <v-icon>mdi-</v-icon>  -->
            Ver Pagamentos
          </v-btn>
          <!-- <br /> -->
          <v-btn
            color="success"
            class="ml-2"
            outlined
            dense
            text
            @click="goToDestaques()"
          >
            <!-- <v-icon>mdi-</v-icon> -->
            Comprar Mais
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      name="dialog-ver-proforma"
      v-model="dialogVerProforma"
      width="350"
      transition="dialog-top-transition"
    >
      <v-card>
        <v-card-text class="text-center">
          <v-icon color="success" class="mt-2" size="50"
            >mdi-information-outline</v-icon
          >
          <h6 class="mt-2 text-uppercase">Fatura Proforma</h6>

          <v-col cols="12">
            <span>
              Fatura Proforma gerada com sucesso! Veja a aba a tua direita para
              imprimir.</span
            >
          </v-col>
          <v-card-actions class="justify-center">
            <v-btn color="success" @click="dialogVerProforma = false" outlined
              >Voltar</v-btn
            >
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      name="dialog-loading-dados"
      v-model="loading"
      max-width="300"
      persistent
    >
      <v-card>
        <v-card-text class="text-center">
          <v-progress-circular
            size="30"
            class="mt-6"
            indeterminate
            color="secondary"
          />
          <v-col cols="12">
            <span class="font-weight-medium"> Carregando dados...</span>
          </v-col>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import axios from "axios";
import { LOGIN_URL, SERVIDOR, BASE_URL } from "@/configs/api";
import VueTheMask from "vue-the-mask";
import { barramento } from "@/barramento";

export default {
  components: {},
  data: () => ({
    errors: {},
    isWeb: false,
    message: "",
    dialogPagamentoSuspenso: false,
    comprovativoValido: true,
    loadingComunas: false,
    SERVER: SERVIDOR,
    loadingData: false,
    cartaoError: null,
    pagamentoComCartao: null,
    cartaoNovo: {
      numeroCartao: null,
    },
    cartao: {
      numeroCartao: null,
    },
    comunas: [],
    comunaItem: {},
    dialogPagamentoCartao: false,
    dialogFormaPagamento: false,
    rulesFormEnderecoEnvio: {
      name: [(v) => !!v || "Campo obrigatório"],
      email: [
        (v) => !!v || "É obrigatório informar o email",
        (v) => /.+@.+\..+/.test(v) || "Email inválido",
      ],
      telefone: [(v) => !!v || "Campo obrigatório"],
    },
    dialogVerProforma: false,
    selectedItem: {
      id: null,
      valor_entrega: 0,
      municipioIdEntrega: 0,
    },
    formasEntregas: [],
    dialogFormaEntrega: false,
    token: "",
    proforma: "",
    dialogPagamentoEmAnalise: false,
    comprovativoBancario: null,
    cliente: {
      provinciaEntrega: 1,
    },
    provincias: [],
    dialogFinalizarCompra: false,
    forma_de_pagamento: {
      dataPagamentoBanco: "",
    },
    pagamento: {
      banco: {
        coordernada_bancaria: {},
      },
    },
    formasDePagamento: [],
    banco: {},
    dialogMetodosPagamento: false,
    showAlert: false,
    product: {
      quantity: 0,
    },
    dialogDeleteAllFromCart: false,
    dialogDeleteFromCartDaBD: false,
    cart: [],
    totalProducts: 0,
    totalPrice: 0,
    productsFromStorage: [],
    localProdutos: [],
    dialogDeleteFromCart: false,
    dados: "",
    loading: false,
    token: "",
    dialogUpdateEnderecoEntrega: false,
    dialogUpdateEnderecoFaturacao: false,
    produto: {
      categoria: {},
      classificacao: {
        num_classificacao: "",
      },
    },
    user: {
      cartaoCliente: {},
      cliente: {
        endereco: "",
      },
    },
    carrinho: {
      produtos: [],
      // set Taxa entrega como null
      cobrarTaxaEntrega: null,
    },
    singleSelect: true,
    selected: [],
    headers: [
      {
        text: "Forma de Entrega",
        align: "start",
        sortable: false,
        value: "designacao",
      },
      {
        text: "Custo de Entrega",
        value: "custo_de_entrega",
      },
    ],

    paises: [],
    // provincias: [
    // ],
    municipios: [],
    endereco: {
      pais_id: 1,
      provincia_id: 1,
      municipio_id: 1,
    },
    alert: {
      text: "",
      type: "",
      text2: "",
      type2: "",
    },
    auth: {},
    showpassword: false,
    passoInicial: 1,
    overlay: false,
    query: {},
  }),

  directives: {
    mask: VueTheMask.mask,
  },
  computed: {
    dataValida() {
      return this.dateValidation(this.pagamento.dataPagamentoBanco) === true;
    },

    calcularTotalCarrinho() {
      let total = 0;
      // this.carrinho.produtos.map((itemCarrinho, index) => {
      //   total += itemCarrinho.quantidade * itemcarrinho.produtos.pvp;
      // });
      return total;
    },
  },

  // API FORMAS DE PAGAMENTO
  calcularStock(cart) {
    if (cart.existenciaStock != 0) {
      return parseInt(cart.existenciaStock - cart.quantidade);
    } else return 0;
  },
  showDialogFinalizarCompra() {
    this.dialogFinalizarCompra = true;
  },
  checkMontante() {
    return this.carrinho.totalPagar != this.pagamento.montante;
  },
  getProvincias() {
    const url = `${BASE_URL}/listar/provincias`;
    axios.get(url).then((response) => {
      this.provincias = response.data;
    });
  },
  reloadFormaPagamento(forma_pagamento_id) {
    const url =
      `${LOGIN_URL}/empresa/listarFormaPagamento/` + forma_pagamento_id;
    var TOKEN_SESSAO = localStorage.getItem("TOKEN");
    axios
      .get(url, {
        headers: {
          Authorization: "Bearer " + TOKEN_SESSAO,
        },
      })
      .then((response) => {
        this.forma_de_pagamento = response.data;
      });
  },
  created() {
    barramento.$on("adicionarCarrinho", (evento) => {
      //   this.getTotalProdutosCarrinho();
      //  this.getAllCarrinhoProdutos();
    });
    this.isWeb =
      this.$vuetify.breakpoint.name === "xl" ||
      this.$vuetify.breakpoint.name === "lg" ||
      this.$vuetify.breakpoint.name === "md";
  },
  mounted() {
    if (localStorage.getItem("TOKEN")) {
      this.token = localStorage.getItem("TOKEN");
      this.getUserAuth();
      setTimeout(() => {
        this.token = localStorage.getItem("TOKEN");
        var user = this.user;
        this.cliente.emailEntrega = user.email;
      }, 250);
      this.getMunicipiosByProvinciaId();
      this.getListaFormasEntrega();
      this.getFormasDePagamento();
      this.getProvincias();
      this.getBancosComerciais();
      this.loadClienteCarrinhoProdutos();
    } else {
      this.loadCartFromStorage();
    }
    const cart = sessionStorage.getItem("cart");
    const totalProducts = sessionStorage.getItem("totalProducts");
    const totalPrice = sessionStorage.getItem("totalPrice");

    if (cart && totalProducts && totalPrice) {
      this.cart = JSON.parse(cart);
      this.totalProducts = Number(totalProducts);
      this.totalPrice = Number(totalPrice);
    }
  },

  watch: {},
  methods: {
    handleFileChange(file) {
      const allowedTypes = ["application/pdf", "image/jpeg", "image/png"];

      if (file && !allowedTypes.includes(file.type)) {
        // this.message="Só é permitido arquivos PDF,JPE ou PNG"
        this.message =
          "Por favor, selecione um arquivo com extensão .pdf, .jpg, .jpeg ou .png.";
        //  alert("Por favor, selecione um arquivo com extensão .pdf, .jpg, .jpeg ou .png.")
        // this.$refs.fileInput.reset();
        // this.$refs.fileInput.$el.querySelector('input[type=file]').value = ''; // Limpar o valor do input para reativar o evento de alteração
        // this.$refs.fileInput.$emit('change', null);
      } else {
        this.message = "";
        // alert("Ficheiro Valido")
      }
    },

    goToHome() {
      this.$router.push({
        name: "Home",
      });
    },
    onComprovativoChange() {
      // Verifica se o arquivo possui uma extensão permitida
      const allowedExtensions = [".pdf", ".jpg", ".jpeg", ".png"];
      const fileName = this.comprovativoBancario
        ? this.comprovativoBancario.name
        : "";
      const fileExtension = fileName.slice(
        ((fileName.lastIndexOf(".") - 1) >>> 0) + 2
      );

      if (!allowedExtensions.includes(fileExtension.toLowerCase())) {
        this.$refs.fileInput.reset(); // Limpa o valor do input
        this.message =
          "Por favor, selecione um arquivo com extensão .pdf, .jpg, .jpeg ou .png.";
      } else {
        this.message = "";
      }
    },
    dateValidation(value) {
      if (!value) return "A data é obrigatória";

      const selectedDate = new Date(value);
      const today = new Date();
      const oneWeekAgo = new Date();
      oneWeekAgo.setDate(today.getDate() - 7);
      if (selectedDate > today) {
        return "A data não pode ser depois de hoje";
      }
      if (selectedDate < oneWeekAgo) {
        return "A data de Pagamento deve ser no máximo dentro de uma semana atrás";
      }
      return true;
    },
    goToDestaques() {
      this.$router.push({
        name: "Home",
      });
    },
    checkNovoCartao() {
      var TOKEN_SESSAO = localStorage.getItem("TOKEN");
      let cartao = this.cartaoNovo;
      var cartaoNumero = cartao.numeroCartao;
      const url = `${LOGIN_URL}/empresa/cartaoClienteValido/` + cartaoNumero;
      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + TOKEN_SESSAO,
          },
        })
        .then((response) => {
          if (response.data.message) {
            this.cartaoError = response.data.message;
            console.log(response.data.message);
          } else {
            console.log(response.data);
          }
        })
        .catch((error) => {
          console.log(error.data);
        });
    },
    checkSaldoCartaoNegativo(cartao, carrinho) {
      // Saldo estatico para descomentar
      // cartao.saldo = 35000;
      //  Comentar a linha acima
      return (
        parseFloat(cartao.saldo) < parseFloat(carrinho.totalPagar) &&
        parseFloat(cartao.saldo) != parseFloat(carrinho.totalPagar)
      );
    },

    showDialogPagamentoCartao() {
      this.getUserAuth();
      this.dialogPagamentoCartao = true;
    },
    showDialogFormaPagamento() {
      this.dialogFormaPagamento = true;
    },

    validateFormEnderecoEnvio() {
      var cliente = this.cliente;
      var user = this.user;
      return (
        cliente.pontoReferenciaEntrega != null &&
        user.name != null &&
        user.telefone != null &&
        user.email != null
      );
    },
    goToProdutos() {
      this.$router.push({
        name: "ProdutosEmDestaque",
      });
    },
    redirectToPagamentos() {
      this.$router.push({
        name: "ClientePedidos",
      });
    },
    goBackFormaEntrega() {
      this.pagamentoComCartao = null;
      let cliente = this.cliente;
      if (cliente.tipoEntregaId == 1) {
        // alert("ENTREGA EM CASA");
        this.passoInicial = 3;
      } else {
        // Levantamento na LOJA
        this.passoInicial = 1;
      }
      // alert("LEVANTAMENTO NA LOJA");
    },
    showDialogFormaEntrega() {
      this.dialogFormaEntrega = true;
      this.stepper = 3;
    },
    getFormaEntrega(params) {
      if (params == 1) {
        // alert("REDICIONAR PARA INFORMAR OS DADOS DA CASA");
        this.passoInicial = 3;
        this.cliente.tipoEntregaId = 1;
        this.dialogFormaEntrega = false;
      } else {
        // alert(params);
        this.dialogFormaEntrega = false;
        this.cliente.tipoEntregaId = 2;
        this.dialogFormaPagamento = true;
      }
      // this.dialogFormaEntrega = false;
    },

    getMunicipiosByProvinciaId() {
      let provinciaEntregaId = this.cliente.provinciaEntrega;
      const url = `${LOGIN_URL}/listarMunicipiciosFretePelaProvincia/${provinciaEntregaId}`;
      axios.get(url).then((response) => {
        this.municipios = response.data.data;
        // alert(JSON.stringify(response.data.data));
      });
    },
    checkComprovativoDados() {},

    async printProforma() {
      this.loading = true;
      var TOKEN_SESSAO = localStorage.getItem("TOKEN");
      try {
        let response = await axios.get(
          `${BASE_URL}/carrinho/imprimirProforma`,
          {
            headers: {
              Authorization: "Bearer " + TOKEN_SESSAO,
            },
            responseType: "arraybuffer",
          }
        );

        if (response.status === 200) {
          var file = new Blob([response.data], {
            type: "application/pdf",
          });
          var fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          this.loading = false;

          this.dialogVerProforma = true;
        }
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },

    getComunasByMunicipioId(municipioId) {
      // let municipio_id=municipioId
      this.loadingComunas = true;
      const url = `${LOGIN_URL}/listarComunasFretePeloMunicipio/${municipioId}`;
      axios.get(url).then((response) => {
        this.comunas = response.data.data;
        // alert(JSON.stringify(response.data.data));
        this.loadingComunas = false;
      });
    },

    getListaFormasEntrega() {
      const url = `${LOGIN_URL}/listarTiposEntregas`;
      axios.get(url).then((response) => {
        this.formasEntregas = response.data;
      });
    },
    getUserAuth() {
      this.overlay = true;
      var TOKEN_SESSAO = localStorage.getItem("TOKEN");
      const url = `${LOGIN_URL}/user/meAuth`;
      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + TOKEN_SESSAO,
          },
        })
        .then((response) => {
          this.user = response.data;
          let user = this.user;
          if (user.cartaoCliente != null) {
            // this.cartao = user.cartaoCliente?user.cartaoCliente:null;
            this.cartao = user.cartaoCliente;
          }

          this.pagamentoComCartao = true;
          setTimeout(() => {
            this.overlay = false;
          }, 1000);
        });
    },
    // API FORMAS DE PAGAMENTO
    calcularStock(cart) {
      if (cart.existenciaStock != 0) {
        return parseInt(cart.existenciaStock - cart.quantidade);
      } else return 0;
    },
    showDialogFinalizarCompra() {
      this.dialogFinalizarCompra = true;
    },
    checkMontante() {
      return this.carrinho.totalPagar != this.pagamento.montante;
    },
    getProvincias() {
      const url = `${BASE_URL}/listar/provincias`;
      axios.get(url).then((response) => {
        // alert(JSON.stringify(response.data));
        this.provincias = response.data;
      });
    },
    reloadFormaPagamento(forma_pagamento_id) {
      const url =
        `${LOGIN_URL}/empresa/listarFormaPagamento/` + forma_pagamento_id;
      var TOKEN_SESSAO = localStorage.getItem("TOKEN");
      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + TOKEN_SESSAO,
          },
        })
        .then((response) => {
          this.forma_de_pagamento = response.data;
        });
    },

    // getPagamento(pagamento) {
    //   alert(JSON.stringify(pagamento));
    // },
    getBanco(banco) {
      // alert(JSON.stringify(banco));
      this.pagamento.banco = banco[0];
    },
    getBancosComerciais() {
      const url = `${LOGIN_URL}/empresa/listarBancos`;
      var TOKEN_SESSAO = localStorage.getItem("TOKEN");
      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + TOKEN_SESSAO,
          },
        })
        .then((response) => {
          this.banco = response.data.data;
          // this.pagamento.banco
          setTimeout(() => {
            this.loading = false;
          }, 1500);
        });
    },
    getFormasDePagamento() {
      const url = `${LOGIN_URL}/empresa/listarFormaPagamento`;
      var TOKEN_SESSAO = localStorage.getItem("TOKEN");
      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + TOKEN_SESSAO,
          },
        })
        .then((response) => {
          this.formasDePagamento = response.data;
          // alert(JSON.stringify(response.data));
          setTimeout(() => {
            this.loading = false;
          }, 1500);
        });
    },
    setPagamento() {
      this.dialogFormaPagamento = false;
      (this.dialogPagamentoCartao = false), (this.passoInicial = 5);
      // setTimeout(() => {
      //   this.getBancosComerciais();
      //   this.getFormasDePagamento();
      // }, 1000);

      this.dialogMetodosPagamento = false;
    },
    showDialogMetodosPagamento() {
      this.cartao = {};
      this.pagamentoComCartao = false;
      this.dialogMetodosPagamento = true;
    },
    // API FORMAS DE PAGAMENTO
    calcularTotalComEnvio(total) {
      var totalEntrega = parseFloat(this.comunaItem.valor_entrega);
      // var total = 0
      // cliente.tipoEntregaId == 1
      if (
        this.cliente.tipoEntregaId == 1 &&
        this.carrinho.cobrarTaxaEntrega == true
      ) {
        total = parseFloat(total + totalEntrega);
      }
      {
        total = parseFloat(total);
      }

      return total;
    },
    calculateSubtotalLocal(cart) {
      return parseFloat(cart.pvp * cart.quantity);
    },
    showDialogDeleteAllFromCartStorage() {
      this.dialogDeleteAllFromCart = true;
    },
    showDialogDeleteFromCartStorage(cart) {
      this.produto = cart;
      this.dialogDeleteFromCart = true;
    },
    emptyStorageCart() {
      sessionStorage.setItem("totalPrice", 0);
      sessionStorage.setItem("totalProducts", 0);
      sessionStorage.setItem("cart", []);
      setTimeout(() => {
        window.location.reload();
      }, 1200);
      // this.updateCart();
    },
    addToCartStorage(product) {
      const existingProduct = this.cart.find((p) => p.uuid === product.uuid);
      if (existingProduct) {
        product.quantity = parseInt(product.quantity + 1);
      } else {
        product.quantity = parseInt(product.quantity + 1);
        this.cart.push(product);
      }
      this.totalProducts++;
      this.totalPrice += product.pvp;
      this.updateCart();
    },

    decreaseQuantity(product) {
      if (product.quantity > 1) {
        product.quantity--;
        this.totalProducts--;
        this.totalPrice -= product.pvp;
        this.updateCart();
      }
    },
    increaseQuantity(product) {
      product.quantity++;
      this.totalProducts++;
      this.totalPrice += product.pvp;
      this.updateCart();
    },
    removeFromStorageCart(product) {
      const index = this.cart.indexOf(product);
      if (index > -1) {
        this.cart.splice(index, 1);
        this.totalProducts -= product.quantity;
        this.totalPrice -= product.pvp * product.quantity;
        this.updateCart();
        this.dialogDeleteFromCart = false;
      }
    },
    updateCart() {
      sessionStorage.setItem("cart", JSON.stringify(this.cart));
      sessionStorage.setItem("totalProducts", this.totalProducts);
      sessionStorage.setItem("totalPrice", this.totalPrice);
    },
    totalFromStorage() {
      let produtos = this.productsFromStorage;
      let total = 0;
      produtos.map((itemCarrinho, index) => {
        total += itemCarrinho.quantidade * itemCarrinho.pvp;
      });
      return parseFloat(total);
    },
    loadCartFromStorage() {
      this.localProdutos = JSON.parse(sessionStorage.getItem("shoppingCart"));
    },
    decreaseProductFromStorage(produto) {
      // alert(JSON.stringify(produto));
    },
    encreaseProductFromStorage(produto) {
      //  this.produto.quantidade++
      // alert(JSON.stringify(produto));
    },
    removeFromCart(produto) {
      let produto_id = produto.id;
      // alert(produto_id);
      // return;
      var TOKEN_SESSAO = localStorage.getItem("TOKEN");
      //  alert(produto_uuid)
      const url = `${BASE_URL}/remover/produto/carrinho`;
      // remover/produto/carrinho
      axios
        .post(
          url,
          {
            id: produto_id,
          },
          {
            headers: {
              Authorization: "Bearer " + TOKEN_SESSAO,
            },
          }
        )
        .then((response) => {
          // alert(JSON.stringify(response.data));
          this.message = response.data.message;
          this.getAllCarrinhoProdutos();
          this.dialogDeleteFromCartDaBD = false;
          barramento.$emit("adicionarCarrinho");
        });
    },
    showDialogDeleteFromCart(cart) {
      this.produto = cart;
      // alert(JSON.stringify(cart));
      this.dialogDeleteFromCartDaBD = true;
    },
    getAllCarrinhoProdutos() {
      var TOKEN_SESSAO = localStorage.getItem("TOKEN");
      const url = `${BASE_URL}/carrinho/get/my/produtos`;
      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + TOKEN_SESSAO,
          },
        })
        .then((response) => {
          this.carrinho = response.data.data;
          // alert(JSON.stringify(response.data));
        });
    },
    async setFinalizarCompra() {
      // let forma_de_pagamento = this.forma_de_pagamento;
      this.loading = true;

      // setTimeout(() => {
      //   this.loading = false;
      //   this.dialogPagamentoSuspenso = true;
      // }, 4000);

      let pagamento = this.pagamento;
      let cliente = this.cliente;
      let cartao = this.cartao;

      this.dialogFinalizarCompra = false;
      const url = `${BASE_URL}/enviarPagamentoCompraOnline`;
      let user = this.user;
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      let formData = new FormData();
      var payment = {};
      formData.append("type", "web");
      formData.append("comprovativoBancario", this.comprovativoBancario);
      payment.bancoId = pagamento.banco_id;
      payment.formaPagamentoId = pagamento.forma_pagamento_id;
      payment.enderecoEntrega = user.cliente.endereco
        ? user.cliente.endereco
        : null;
      payment.nomeUserEntrega = user.name;
      payment.apelidoUserEntrega = user.name;
      payment.telefoneUserEntrega = user.telefone;
      payment.provinciaIdEntrega = 1;
      payment.municipioIdEntrega = this.selectedItem.id;
      payment.comunaId = this.comunaItem.id ? this.comunaItem.id : 0;
      payment.emailEntrega = user.email;
      payment.cobrarTaxaEntrega = this.carrinho.cobrarTaxaEntrega;
      payment.pontoReferenciaEntrega = cliente.pontoReferenciaEntrega
        ? cliente.pontoReferenciaEntrega
        : null;

      payment.observacaoEntrega = cliente.observacaoEntrega
        ? cliente.observacaoEntrega
        : null;

      payment.tipoEntregaId = cliente.tipoEntregaId;
      payment.numeroCartaoCliente = cartao.id ? cartao.numeroCartao : null;
      payment.dataPagamentoBanco = pagamento.dataPagamentoBanco;
      formData.append("pagamento", JSON.stringify(payment));

      axios
        .post(url, formData, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          // alert(JSON.stringify(response.data));
          setTimeout(() => {
            this.loading = false;
            this.dialogPagamentoEmAnalise = true;
          }, 50);
        })
        .catch((error) => {
          if (error.response) {
            this.loading = false;
            this.alert.type = "error";

            if (error.response.status == 400) {
              this.message = error.response.data.message;
              this.dialogPagamentoSuspenso = true;
            }
            // console.log(
            //   "Erro de resposta do servidor: ",
            //   error.response.data.message
            // );
            // console.log("Status de erro:", error.response.status);
          }
        });
    },
    showDialogUpdateEnderecoEntrega() {
      this.dialogUpdateEnderecoEntrega = true;
    },
    showDialogUpdateEnderecoFaturacao() {
      this.dialogUpdateEnderecoFaturacao = true;
    },
    updateEnderecoFaturacao() {},
    imprimirProforma() {
      this.dialogPagamentoEmAnalise = false;
      window.open(this.proforma);
      setTimeout(() => {
        this.$router.push({
          path: "/",
        });
      }, 2000);
    },
    formatePrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    encreaseProductInCart(produto_uuid) {
      this.loadingData = true;

      var TOKEN_SESSAO = localStorage.getItem("TOKEN");
      const url = `${BASE_URL}/carrinho/encrease/qty/produto`;
      axios
        .post(
          url,
          {
            uuid: produto_uuid,
          },
          {
            headers: {
              Authorization: "Bearer " + TOKEN_SESSAO,
            },
          }
        )
        .then(() => {
          // this.showAlert = true;
          // setTimeout(() => {
          this.dados = "Mais uma unidade adicionada ao carrinho com sucesso!";
          //   this.showAlert = false;
          // }, 500);
          barramento.$emit("adicionarCarrinho");

          this.getAllCarrinhoProdutos();
          this.loadingData = false;
        });
    },
    decreaseProductInCart(produto_uuid) {
      this.loadingData = true;
      var TOKEN_SESSAO = localStorage.getItem("TOKEN");
      const url = `${BASE_URL}/carrinho/decrease/qty/produto`;
      axios
        .post(
          url,
          {
            uuid: produto_uuid,
          },
          {
            headers: {
              Authorization: "Bearer " + TOKEN_SESSAO,
            },
          }
        )
        .then(() => {
          this.dados =
            "Menos uma unidade decrementada no carrinho com sucesso!";
          this.getAllCarrinhoProdutos();
          this.loadingData = false;
          barramento.$emit("adicionarCarrinho");
        })
        .catch((error) => {
          console.log(`error ${error}`);
        });
    },

    loadClienteCarrinhoProdutos() {
      this.loading = true;
      var TOKEN_SESSAO = localStorage.getItem("TOKEN");
      const url = `${BASE_URL}/carrinho/get/my/produtos`;
      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + TOKEN_SESSAO,
          },
        })
        .then((response) => {
          this.carrinho = response.data.data;
        })
        .catch((error) => {
          console.log(`error ${error}`);
        });
      setTimeout(() => {
        this.loading = false;
      }, 1500);
    },
  },
};
</script>

<style lang="scss" scoped>
.product-slider {
  .product-slider-click {
    display: flex;
    justify-content: center;

    ::v-deep .slick-slide {
      width: 80px !important;
      margin-right: 16px;
      border: 1px solid rgb(218, 225, 231);
      border-radius: 5px;
    }

    ::v-deep .slick-current {
      border: 1px solid #d23f57;
      border-radius: 5px;
    }
  }
}
</style>
<style>
#rounded-card {
  border-radius: 25px;
}
</style>
